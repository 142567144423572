import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useTranslation } from "react-i18next";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "ua",
    whitelist: ["ua", "ru"], // Змінили на російську
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
    },
    resources: {
      ru: {
        translation: {
          main: "Главная",
          about: "О нас",
          tovarPo: "Товарные услуги",
          registerK: "Регистрация на маркетплейсах",
          contact: "Контакты",
          connect: "Связаться",
          mainH1:
            "Запуск и ведение магазина на маркетплейсах (Rozetka, prom, эпицентр) без лишних усилий!",
          mainP:
            "Мы поможем вам открыть магазин на Rozetka и других платформах, обеспечивая полный сопровождение, автоматизацию процессов и оптимизацию для увеличения продаж.",
          ourPoslug: "Наши услуги",
          secondBlockOneTitle:
            "Полное управление вашими товарами и ценами на Розетке — с минимальными усилиями!",
          secondBlockOneDesc:
            "Успешное ведение магазина на Розетке требует точного и грамотного управления ассортиментом, ценами и наличием товаров. Мы предлагаем комплексное решение, которое освободит вас от рутинной работы и ускорит процессы, позволяя сосредоточиться на росте бизнеса.",
          secondBlockTwoTitle:
            "Регистрация магазина на Розетке — без стресса и с гарантией результата!",
          secondBlockTwoDesc:
            "Многие продавцы сталкиваются с трудностями при регистрации магазина на Розетке: неясные отказы, отсутствие объяснений и непонимание, что делать дальше. Даже если ваши товары подходят по всем критериям, прохождение этапов может затянуться. Мы поможем вам пройти через этот процесс эффективно и без стресса.",
          detalnishe: "Подробнее",
          newBlockFirstTitle: "Регистрация магазина на маркетплейсах",
          weGiceFor: "Мы берем на себя:",
          tiOne:
            "Практический анализ ваших товаров и шансы на их успешное размещение.",
          tiTwo:
            "Создание корректного XML прайс-листа, который обеспечит гладкое добавление товаров.",
          tiThre:
            "Оформление всех документов и заключение договора с Розеткой.",
          tiFour:
            "Пошаговые инструкции по настройке кабинета продавца и управлению магазином.",
          tiFive:
            "Избежание типичных ошибок, которые могут помешать синхронизации данных с вашими поставщиками и платформами.",
          chomyVagTitle: "Почему это важно для вас",
          chomuVagDesc:
            "Розетка — это сложная система с множеством нюансов. Без правильных шагов вы рискуете потратить месяцы на исправление ошибок, что замедлит старт продаж. Мы сделаем так, чтобы ваш магазин заработал как можно быстрее и приносил прибыль с минимальными задержками.",
          listvagOne: "Средний срок регистрации: 4-6 недель.",
          listvagTwo: "Оплата: 50% предоплата и 50% после активации магазина.",
          listvagThre:
            "Доверьте регистрацию профессионалам — сэкономьте время и начните продавать быстрее! ",
          zadOne: "Довольные клиенты",
          zadTwo: "Члены команды",
          zadThre: "Успешные кейсы",
          zadFour: "Уровень удовлетворенности",
          etapuO: "Этапы регистрации магазина",
          firstEtap:
            'Многие Продавцы сталкиваются с тем, что пройти регистрацию магазина на Розетке — сложно, а для некоторых даже невозможно. В случае отказа, менеджер Розетки не предоставляет подробных объяснений и не пытается исправить ситуацию. Обычно отправляется одно сообщение с текстом: "В настоящее время маркетплейс не заинтересован в Ваших товарах", и на этом всё заканчивается. И действительно, у Продавца "свой склад" и "не дропшиппинг" и т.п., но "Решение принято. В случае изменений мы Вам сообщим".',
          secondEtap:
            'Если процесс регистрации магазина успешен, Продавцу сначала звонит Первый менеджер, который собирает информацию о Продавце и товаре, запрашивает прайс-лист в формате XML и передает эту информацию на проверку. Многие Продавцы на этом этапе думают, что "всё почти готово, осталось предоставить прайс, и магазин готов". Однако, это ещё не Второй менеджер по Привлечению, который будет курировать оформление магазина Продавца.',
          threEtap:
            'Затем Продавца передают Второму менеджеру по Привлечению клиентов. Его работа — оформление магазинов. Поэтому услышать от него о сложностях в оформлении практически невозможно. Обычно это буквально 2-3 пункта: "Вот это предоставьте, и активируем магазин". Так будет даже в случае, когда Розетка сама обращается к Продавцу с предложением сотрудничества. Всё идёт поэтапно, и ни один пункт не будет пропущен.',
          fourEtap:
            "Затем начинается процесс оформления магазина, который включает все вопросы, связанные с товарами, брендами и категориями, запрещёнными к продаже, бухгалтерскими и юридическими документами, отображением информации на маркетплейсе и т.п. Всё это описано в Справочном разделе Розетки, и Продавцы, работающие хотя бы несколько месяцев, уже примерно ориентируются в этих правилах. А новый Продавец будет узнавать это поэтапно, раз за разом, каждый раз открывая что-то новое и тратя дни и недели своего времени.",
          fiveEtap:
            "В целом, нет ничего страшного в самостоятельном оформлении. Это обычные будни Предпринимателя, и потратить на запуск проекта пару месяцев — совершенно нормальная ситуация. Но со временем Продавец узнаёт о функционале кабинетов и типичных ошибках на старте. Например, что не стоило создавать XML прайс-лист со своего Прома и менять на Проме все товары под Розетку, а лучше было сделать это отдельно в ПрайсКриеторе Розетки. Если ему предоставили кабинет ПрайсКриетор, то не стоит создавать товары вручную, так как они не будут иметь связи с Промом или Вашим сайтом, и настроить синхронизацию цен и наличия будет почти невозможно. Об этом и многом другом никто не расскажет.",
          sixEtap:
            "Маркетплейс Розетка — это большая многофункциональная система, которую нужно настроить под свой Магазин и умело использовать с минимальным количеством ошибок. Тогда она будет работать очень успешно и приносить прибыль.",
          sevenEtap:
            "И вот теперь мы подошли к Старту продаж на Розетке. Продавца передают Третьему менеджеру по Адаптации на 6 месяцев. Теперь нужно раскрутить магазин, заработать рейтинг, добавить все остальные товары или добавлять их на постоянной основе, настроить и подключить все методы доставки и оплаты, постоянно поддерживать актуальность наличия и цен из своей базы и от множества поставщиков, знать нюансы работы и быстро ориентироваться в двух кабинетах Розетки, и очень важно совершать как можно меньше ошибок. Вообще не совершать ошибок — так не бывает, и это аксиома. Но находясь в пределах 70-85% положительных заказов, у Вас всё будет в порядке. Выше почти не встречается, ниже не интересно ни Вам, ни Розетке.",
          lastTitle:
            "Мы предлагаем вам полное сопровождение в регистрации магазина на Розетке, включая:",
          lastPFirst:
            "Анализ и оценку вашего ассортимента: Подскажем, какие товары имеют высокий потенциал и как их лучше позиционировать.",
          lastPSecond:
            "Готовый XML прайс-лист: Быстро создадим профессиональный прайс-лист с корректными настройками для Розетки.",
          lastPThre:
            "Оформление всех необходимых документов и заключение договора с маркетплейсом.",
          lastPFour:
            "Пошаговые инструкции: Подробно объясним, как пользоваться кабинетом продавца, как добавлять товары и управлять своим магазином.",
          lastPFive:
            "Защита от ошибок: Предотвратим проблемы с синхронизацией цен, наличия и другими техническими нюансами, которые могут замедлить ваши продажи.",
          lastPSix:
            "Сопровождение на старте: Поможем настроить все методы доставки и оплаты, организуем первые продажи и поддержим ваш магазин на пути к стабильной работе и росту.",
          rozetcaTitle:
            "Полное управление вашими товарами и ценами на Розетке — с минимальными усилиями!",
          rozetcaWrpaO:
            "Успешное ведение магазина на Розетке требует точного и грамотного управления ассортиментом, ценами и наличием товаров. Мы предлагаем комплексное решение, которое освободит вас от рутинной работы и ускорит процессы, позволяя сосредоточиться на росте бизнеса.",
          rozetcaWrapD:
            "Наши услуги по наполнению и управлению товарами включают:",
          includeUseOne: "Проверка возможности размещения товара:",
          includeUseOnedescOne:
            "Убедимся, что ваши товары не попадают в стоп-категории и стоп-бренды маркетплейса.",
          includeUseOnedescTwo:
            "Проверим качество фото на соответствие стандартам Розетки: без водяных знаков, четкие и с правильным представлением товара.",
          includeUseOnedescThre:
            "Убедимся, что все необходимые данные по товарам присутствуют и соответствуют требованиям Розетки.",
          includeUseTwo: "Импорт товаров с привязкой к вашему сайту:",
          includeUseTwodescOne:
            "Мы обеспечим автообновление цен и остатков, чтобы ваш магазин всегда оставался актуальным.",
          includeUseThre: "Точное размещение в категориях:",
          includeUseThredescOne:
            "Мы правильно распределим товары по категориям, что повысит их видимость и упростит поиск покупателям.",
          includeUseFour: "Подготовка товаров под требования маркетплейса:",
          includeUseFourdescOne:
            "Оптимизируем названия товаров по шаблонам Розетки для улучшения ранжирования в поиске.",
          includeUseFourdescTwo:
            "Приведем описания в соответствие с требованиями маркетплейса, удалив ненужную информацию.",
          includeUseFourdescThre:
            "Разместим фотографии и заполним характеристики товаров, чтобы они были полностью соответствующими требованиям и попадали в фильтры поиска.",
          includeUseFive: "Загрузка и модерация товаров:",
          includeUseFivedescOne:
            "Добавим ваши товары в выгрузку, отправим на модерацию и внесем правки по итогам проверки.",
          includeUseSix: "Настройка синхронизации наличия и цен:",
          includeUseSixdescOne:
            "Мы настроим автоматическое обновление данных, чтобы ваш ассортимент всегда был актуален.",
          bigDesckInRoz:
            "Если у вас уже есть товары на платформе, но сложно управлять их ценами и остатками, мы настроим привязку к вашему сайту и обеспечим удобное управление через автообновление. Почему это важно для вас? Маркетплейс Розетка предъявляет строгие требования к размещению товаров, и соблюдение всех правил может занять много времени. Наше решение позволяет вам сэкономить время и избежать ошибок, что увеличит ваши шансы на успешные продажи с самого старта. Ускорьте свой путь к продажам на Розетке с минимальными усилиями — мы всё сделаем за вас!",
          ourInclude: "Наши услуги включают:",
          poslugIone: "Синхронизация остатков, наличия и цен",
          poslugIoneDecs:
            "Поддерживайте актуальные данные о товарах без ручной работы.",
          poslugITwo: "Изменение и оптимизация параметров магазина",
          poslugITwoDecs:
            "Корректируйте настройки магазина для максимальной видимости и продаж.",
          poslugIThre: "Сопоставление параметров товаров",
          poslugIThreDecs:
            "Убедитесь, что ваши товары соответствуют требованиям Розетки и отображаются правильно.",
          poslugIFour: "Выгрузка товаров в XML для Розетки",
          poslugIFourDecs:
            "Автоматическая выгрузка данных для мгновенного обновления ассортимента.",
          poslugIFive: "Массовое редактирование товаров",
          poslugIFiveDecs:
            "Экономьте время на обновлении характеристик большого количества товаров.",
          poslugISix: "Редактирование карточек товаров",
          poslugISixDecs:
            "Создавайте привлекательные и информативные описания, которые привлекут внимание покупателей.",
          poslugISeven: "Редактирование товаров через XML и Excel",
          poslugISevenDecs:
            "Обновляйте данные о статусе, ценах и наличии в пару кликов.",
          poslugIeight: "Автоматизация обновлений",
          poslugIeightDecs:
            "Настройте автообновление XML, чтобы ваши товары всегда были актуальны.",
          poslugINine: "Перенос товаров с Прома на Розетку",
          poslugINineDecs:
            "Легкий переход ваших товаров с других платформ на Розетку без потери данных.",
          whiNeedR:
            "Почему это важно для вас? Ручное управление товарами отнимает много времени и ресурсов, особенно если у вас большой ассортимент. Наша услуга позволяет автоматизировать рутинные процессы и снизить количество ошибок. Мы поможем вам быстро и точно обновлять цены, наличие и другие параметры, что увеличит эффективность вашего магазина и ускорит продажи.",
          contactUsO: "Свяжитесь с нами",
          sendJf: "Отправить",
          magOurPartners: "Магазины наших партнеров",
          gaidPoReg: "Бесплатный гайд по регистрации",
          whyWeAre: "Отличия нашей компании, которые работают на вашу выгоду:",
          whyWeAreOne:
            "1.  Отсутствие абонплат — вы платите только за размещённые заказы. Никаких фиксированных платежей! Хотите остановить добавление товаров на месяц или два? Без проблем. Оплата только за те заказы, которые вы утвердили. Это гибкость, которая позволяет вам экономить и эффективно планировать бюджет.",
          whyWeAreTwo:
            "2.  Мы сделаем работу за вас. Забудьте о долгих часах изучения инструкций и бесконечных попытках разобраться в системе. Ваши товары добавляет и оптимизирует профессиональный категорийный менеджер. Это гарантирует точность и максимальную эффективность.",
          whyWeAreThre:
            "3.  Защита от ошибок и потери времени. Мы заранее предупредим о закрытых брендах, высоких комиссиях, неподходящих категориях или других рисках. Вам не придётся тратить время на исправление ошибок или разбираться в сложностях.",
          whyWeAreFour:
            "4.  Индивидуальный подход к вашим задачам. Каждый бизнес уникален, и мы учитываем все ваши нюансы: от особенностей работы с поставщиками до выбора способа доставки (например, Новая Почта или ТВ Розетки, выгрузка или ручные товары). Мы помним все мелочи, которые делают ваш бизнес успешным.",
          whyWeAreFive:
            "5.  Обязательный план масштабирования на маркетплейсы.Новый товар? Мы сразу рассматриваем стратегию его продаж: от выбора каналов реализации до обновления цены и наличия.",
          whyWeAreSix:
            "6.  Решаем сложные технические задачи.Мы берем на себя взаимодействие с поставщиками, маркетплейсами, хостингами и другими системами. Вам не придётся беспокоиться о форматах данных или поддержке — всё это сделаем мы.",
          whyWeAreSeven:
            "7.  Опыт и уникальная база знаний. С 2018 года мы реализовали десятки нестандартных кейсов. То, что не описано в справках и руководствах, для нас — обычная работа. Вы получаете доступ к нашему богатому опыту, что гарантирует ваш успех. Эти преимущества делают работу с нами максимально простой, удобной и результативной!",
        },
      },
      ua: {
        translation: {
          main: "Головна",
          about: " Про нас",
          tovarPo: "Товарні послуги",
          registerK: "Реєстрація на маркетплейсах",
          contact: "Контакти",
          connect: "Звʼязатись",
          mainH1:
            "Запуск та ведення магазину на маркетплейсах(Rozetka, prom, епіцентр) без зайвих зусиль!",
          mainP:
            "Ми допоможемо вам відкрити магазин на Розетці та інших платформах, забезпечуючи повний супровід, автоматизацію процесів і оптимізацію для зростання продажів.",
          ourPoslug: "Наші послуги",
          secondBlockOneTitle:
            "Повне управління вашими товарами та цінами на Розетці — з мінімальними зусиллями!",
          secondBlockOneDesc:
            "Успішне ведення магазину на Розетці потребує точного та грамотного управління асортиментом, цінами та наявністю товарів. Ми пропонуємо комплексне рішення, яке звільнить вас від рутинної роботи та пришвидшить процеси, дозволяючи зосередитися на зростанні бізнесу.",
          secondBlockTwoTitle:
            "Реєстрація магазину на Розетці — без стресу та з гарантією результату!",
          secondBlockTwoDesc:
            "Багато продавців стикаються з труднощами при реєстрації магазину на Розетці: неясні відмови, відсутність пояснень і нерозуміння, що робити далі. Навіть якщо ваші товари відповідають усім критеріям, проходження етапів може затягнутися. Ми допоможемо вам пройти цей процес ефективно та без стресу.",
          detalnishe: "Детальніше",
          newBlockFirstTitle: "Реєстрація магазину на маркетплейсах",
          weGiceFor: "Ми беремо на себе:",
          tiOne:
            "Практичний аналіз ваших товарів і шанси на їх успішне розміщення.",
          tiTwo:
            "Створення коректного XML прайс-листа, який забезпечить гладке додавання товарів.",
          tiThre: "Оформлення всіх документів і укладання договору з Розеткою.",
          tiFour:
            "Покрокові інструкції з налаштування кабінету продавця та управління магазином.",
          tiFive:
            "Уникнення типових помилок, які можуть завадити синхронізації даних з вашими постачальниками та платформами.",
          chomyVagTitle: "Чому це важливо для вас",
          chomuVagDesc:
            "Розетка — це складна система з багатьма нюансами. Без правильних кроків ви ризикуєте витратити місяці на виправлення помилок, що сповільнить старт продажів. Ми зробимо так, щоб ваш магазин запрацював якнайшвидше і приносив прибуток з мінімальними затримками.",
          listvagOne: "Середній термін реєстрації: 4-6 тижнів.",
          listvagTwo:
            "Оплата: 50% передоплата та 50% після активації магазину.",
          listvagThre:
            "Довірте реєстрацію професіоналам — заощадьте час і почніть продавати швидше!",
          zadOne: "Задоволені клієнти",
          zadTwo: "Члени команди",
          zadThre: "Успішні кейси",
          zadFour: "Рівень задоволеності",
          etapuO: "Етапи реєстрації магазину",
          firstEtap:
            'Багато Продавців стикаються з тим, що пройти реєстрацію магазину на Розетці — складно, а для деяких навіть неможливо. У разі відмови, менеджер Розетки не надає детальних пояснень і не намагається вирівняти ситуацію. Зазвичай надсилається одне повідомлення з текстом: "Наразі маркетплейс не зацікавлений у Ваших товарах", і на цьому все закінчується. І дійсно, у Продавця "свій склад" і "не дропшипінг" тощо, але "Рішення прийняте. У разі змін ми Вас повідомимо".',
          secondEtap:
            'Якщо процес реєстрації магазину успішний, Продавцю спершу телефонує Перший менеджер, який збирає інформацію про Продавця і товар, просить прайс-лист у форматі XML і передає цю інформацію на перевірку. Багато Продавців на цьому етапі думають, що "все майже готове, залишилось надати прайс і магазин буде готовий". Однак, це ще навіть не Другий менеджер  із залучення, який буде курувати оформлення магазину Продавця.',
          threEtap:
            'Далі Продавця передають Другому менеджеру із залучення. Його робота — це оформлення магазинів. Тому почути від нього про труднощі в оформленні практично неможливо. Зазвичай це буквально 2-3 пункти: "Ось це надайте, і активуємо магазин". Так буде навіть у випадку, коли Розетка сама звертається до Продавця з пропозицією співпраці. Усе йде поетапно, і жоден пункт не буде пропущено.',
          fourEtap:
            "Далі розпочинається процес оформлення магазину, який включає всі питання щодо роботи з товарами, брендами та категоріями, забороненими до продажу, з бухгалтерськими та юридичними документами, відображенням інформації на маркетплейсі тощо. Усе це описано в Довідковому розділі Розетки, і Продавці, які працюють хоча б кілька місяців, вже приблизно орієнтуються у цих правилах. А новий Продавець дізнаватиметься це поступово, раз за разом, щоразу відкриваючи щось нове, витрачаючи дні та тижні свого часу.",
          fiveEtap:
            "В цілому, немає нічого страшного у самостійному оформленні. Це звичайні будні Підприємця, і витратити на запуск проєкту кілька місяців — цілком нормальна ситуація. Але з часом Продавець дізнається про функціонал кабінетів і типові помилки на старті. Наприклад, про те, що не варто було створювати XML прайс-лист зі свого Прома і змінювати на Промі всі товари під Розетку, а варто було зробити це окремо в ПрайсКрієторі Розетки. Якщо йому надали кабінет ПрайсКрієтор, то не варто створювати товари вручну, бо так вони не матимуть зв'язку з Промом або Вашим сайтом, і налаштувати синхронізацію цін та наявності буде майже неможливо. Про це та багато іншого ніхто не розповість.",
          sixEtap:
            "Маркетплейс Розетка — це велика багатофункціональна система, яку потрібно налаштувати під свій Магазин і вміло використовувати з мінімальною кількістю помилок. Тоді вона працюватиме дуже успішно та приноситиме прибуток.",
          sevenEtap:
            "І от тепер ми підійшли до Старту продажів на Розетці. Продавця передають Третьому менеджеру з Адаптації на 6 місяців. Тепер потрібно розкрутити магазин, заробити рейтинг, додати всі інші товари або додавати їх на постійній основі, налаштувати та підключити всі методи доставки й оплати, постійно підтримувати актуальність наявності та цін у своїй базі та від безлічі постачальників, знати нюанси роботи й швидко орієнтуватися в двох кабінетах Розетки, і дуже важливо робити якомога менше помилок. Взагалі не робити помилок — так не буває, і це аксіома. Але дотримуючись меж 70-85% позитивних замовлень, у Вас все буде гаразд. Вищі показники майже не зустрічаються, нижчі не цікаві ані Вам, ані Розетці.",
          lastTitle:
            "Ми пропонуємо вам повний супровід у реєстрації магазину на Розетці, включаючи:",
          lastPFirst:
            "Аналіз і оцінку вашого асортименту: Порадимо, які товари мають високий потенціал і як їх краще позиціонувати.",
          lastPSecond:
            "Готовий XML прайс-лист: Швидко створимо професійний прайс-лист з коректними налаштуваннями для Розетки.",
          lastPThre:
            "Оформлення всіх необхідних документів і укладення договору з маркетплейсом.",
          lastPFour:
            "Покрокові інструкції: Детально пояснимо, як користуватися кабінетом продавця, як додавати товари та керувати своїм магазином.",
          lastPFive:
            "Захист від помилок: Уникнемо проблем із синхронізацією цін, наявності та іншими технічними нюансами, які можуть сповільнити ваші продажі.",
          lastPSix:
            "Супровід на старті: Допоможемо налаштувати всі методи доставки та оплати, організуємо перші продажі та підтримаємо ваш магазин на шляху до стабільної роботи та зростання.",
          rozetcaTitle:
            "Повне управління вашими товарами та цінами на Розетці — з мінімальними зусиллями!",
          rozetcaWrpaO:
            "Успішне ведення магазину на Розетці вимагає точного і грамотного управління асортиментом, цінами та наявністю товарів. Ми пропонуємо комплексне рішення, яке звільнить вас від рутинної роботи і прискорить процеси, дозволяючи зосередитися на зростанні бізнесу.",
          rozetcaWrapD:
            "Наші послуги з наповнення та управління товарами включають:",
          includeUseOne: "Перевірка можливості розміщення товару:",
          includeUseOnedescOne:
            "Переконаємося, що ваші товари не потрапляють у стоп-категорії та стоп-бренди маркетплейсу.",
          includeUseOnedescTwo:
            "Перевіримо якість фото на відповідність стандартам Розетки: без водяних знаків, чіткі та з правильним відображенням товару.",
          includeUseOnedescThre:
            "Переконаємося, що всі необхідні дані по товарам присутні і відповідають вимогам Розетки.",
          includeUseTwo: "Імпорт товарів з прив'язкою до вашого сайту:",
          includeUseTwodescOne:
            "Забезпечимо автооновлення цін та залишків, щоб ваш магазин завжди залишався актуальним.",
          includeUseThre: "Точне розміщення в категоріях:",
          includeUseThredescOne:
            "Правильно розподілимо товари по категоріях, що підвищить їх видимість та спростить пошук для покупців.",
          includeUseFour: "Підготовка товарів під вимоги маркетплейсу:",
          includeUseFourdescOne:
            "Оптимізуємо назви товарів за шаблонами Розетки для покращення ранжування в пошуку.",
          includeUseFourdescTwo:
            "Приведемо описи у відповідність з вимогами маркетплейсу, видаливши непотрібну інформацію.",
          includeUseFourdescThre:
            "Розмістимо фотографії та заповнимо характеристики товарів, щоб вони відповідали вимогам та потрапляли у фільтри пошуку.",
          includeUseFive: "Завантаження та модерація товарів:",
          includeUseFivedescOne:
            "Додамо ваші товари у вигрузку, надішлемо на модерацію та внесемо правки за результатами перевірки.",
          includeUseSix: "Налаштування синхронізації наявності та цін:",
          includeUseSixdescOne:
            "Налаштуємо автоматичне оновлення даних, щоб ваш асортимент завжди був актуальним.",
          bigDesckInRoz:
            "Якщо у вас вже є товари на платформі, але складно керувати їх цінами та залишками, ми налаштуємо прив'язку до вашого сайту та забезпечимо зручне управління через автооновлення. Чому це важливо для вас? Маркетплейс Розетка висуває суворі вимоги до розміщення товарів, і дотримання всіх правил може зайняти багато часу. Наше рішення дозволяє вам зекономити час і уникнути помилок, що підвищить ваші шанси на успішні продажі з самого початку. Прискорте свій шлях до продажів на Розетці з мінімальними зусиллями — ми зробимо все за вас!",
          ourInclude: "Наші послуги включають:",
          poslugIone: "Синхронізація залишків, наявності та цін",
          poslugIoneDecs:
            "Підтримуйте актуальні дані про товари без ручної роботи.",
          poslugITwo: "Зміна та оптимізація параметрів магазину",
          poslugITwoDecs:
            "Коригуйте налаштування магазину для максимальної видимості та продажів.",
          poslugIThre: "Співставлення параметрів товарів",
          poslugIThreDecs:
            "Переконайтесь, що ваші товари відповідають вимогам Розетки та відображаються правильно.",
          poslugIFour: "Вивантаження товарів у XML для Розетки",
          poslugIFourDecs:
            "Автоматичне вивантаження даних для миттєвого оновлення асортименту.",
          poslugIFive: "Масове редагування товарів",
          poslugIFiveDecs:
            "Економте час на оновленні характеристик великої кількості товарів.",
          poslugISix: "Редагування карток товарів",
          poslugISixDecs:
            "Створюйте привабливі та інформативні описи, що привертають увагу покупців.",
          poslugISeven: "Редагування товарів через XML та Excel",
          poslugISevenDecs:
            "Оновлюйте дані про статус, ціни та наявність за кілька кліків.",
          poslugIeight: "Автоматизація оновлень",
          poslugIeightDecs:
            "Налаштуйте автооновлення XML, щоб ваші товари завжди були актуальними.",
          poslugINine: "Перенесення товарів з Прома на Розетку",
          poslugINineDecs:
            "Легкий перехід ваших товарів з інших платформ на Розетку без втрати даних.",
          whiNeedR:
            "Чому це важливо для вас? Ручне управління товарами забирає багато часу та ресурсів, особливо якщо у вас великий асортимент. Наша послуга дозволяє автоматизувати рутинні процеси та зменшити кількість помилок. Ми допоможемо вам швидко та точно оновлювати ціни, наявність та інші параметри, що підвищить ефективність вашого магазину та прискорить продажі.",
          contactUsO: "Зв’яжіться з нами",
          sendJf: "Надіслати",
          magOurPartners: "Магазини наших партнерів",
          gaidPoReg: "Безкоштовний гайд по реєстрації",
          whyWeAre: "Відмінності нашої компанії, які працюють на вашу користь:",
          whyWeAreOne:
            "1. Відсутність абонплати — ви сплачуєте лише за розміщені замовлення. Жодних фіксованих платежів! Хочете призупинити додавання товарів на місяць чи два? Без проблем. Оплата лише за ті замовлення, які ви затвердили. Це гнучкість, яка дозволяє вам заощаджувати та ефективно планувати бюджет.",
          whyWeAreTwo:
            "2.  Ми виконуємо роботу за вас. Забудьте про довгі години вивчення інструкцій та нескінченні спроби розібратися в системі. Ваші товари додає та оптимізує професійний категорійний менеджер. Це гарантує точність і максимальну ефективність.",
          whyWeAreThre:
            "3. Захист від помилок і втрати часу. Ми заздалегідь попередимо про закриті бренди, високі комісії, невідповідні категорії чи інші ризики. Вам не доведеться витрачати час на виправлення помилок або розбиратися у складнощах.",
          whyWeAreFour:
            "4.  Індивідуальний підхід до ваших завдань. Кожен бізнес унікальний, і ми враховуємо всі ваші нюанси: від особливостей роботи з постачальниками до вибору способу доставки (наприклад, Нова Пошта чи ТВ Розетки, вивантаження або ручні товари). Ми пам’ятаємо всі деталі, які роблять ваш бізнес успішним.",
          whyWeAreFive:
            "5.  Обов’язковий план масштабування на маркетплейси. Новий товар? Ми одразу розглядаємо стратегію його продажів: від вибору каналів реалізації до оновлення ціни та наявності.",
          whyWeAreSix:
            "6.  Рішення складних технічних завдань. Ми беремо на себе взаємодію з постачальниками, маркетплейсами, хостингами та іншими системами. Вам не доведеться хвилюватися про формати даних чи підтримку — ми все це зробимо за вас.",
          whyWeAreSeven:
            "7.  Досвід і унікальна база знань. З 2018 року ми реалізували десятки нестандартних кейсів. Те, що не описано у довідках та посібниках, для нас — звична робота. Ви отримуєте доступ до нашого багатого досвіду, що гарантує ваш успіх.",
        },
      },
    },
  });

export default i18n;
