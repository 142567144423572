import css from "./style.module.css";
import { useTranslation } from "react-i18next";

const WhyWeAre = () => {
  const { t } = useTranslation();
  return (
    <div className={css.wrapCinnosts}>
      <p className={css.pCinnosti}> {t("whyWeAre")}</p>
      <div className={css.wrapPlfNew}>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreOne")}</p>
        </div>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreTwo")}</p>
        </div>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreThre")}</p>
        </div>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreFour")}</p>
        </div>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreFive")}</p>
        </div>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreSix")}</p>
        </div>
        <div className={css.wrapOpCinnostiNew}>
          <p className={css.cinnostDescP}>{t("whyWeAreSeven")}</p>
        </div>
      </div>
    </div>
  );
};

export default WhyWeAre;
