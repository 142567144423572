import css from "./style.module.css";
import { useTranslation } from "react-i18next";
import popRoz from "../../img/new/rozetka.png";
import epik from "../../img/Epicentrk.png";
const OurWorks = ({ openPopup }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={css.wrapFirstRozetcaNewpo}>
      <p className={css.pDrscInRozetcaSmall}>{t("magOurPartners")}</p>
      <div className={css.wrapBlockPosl}>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://rozetka.com.ua/seller/zoolis/goods/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={popRoz} alt="ZooLis" />
          </a>
          <a
            href="https://rozetka.com.ua/seller/zoolis/goods/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            ZooLis
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://epicentrk.ua/ua/merchant/gabrus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={epik} alt="ZooLis" />
          </a>
          <a
            href="https://epicentrk.ua/ua/merchant/gabrus/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            GabRus
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://rozetka.com.ua/seller/dobre-textilno/goods/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={popRoz} alt="Dobre_Textilno" />
          </a>
          <a
            href="https://rozetka.com.ua/seller/dobre-textilno/goods/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            Dobre_Textilno
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://rozetka.com.ua/seller/_glorias_/goods/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={popRoz} alt="Glorias" />
          </a>
          <a
            href="https://rozetka.com.ua/seller/_glorias_/goods/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            Glorias
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://epicentrk.ua/ua/merchant/blenda-sop/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={epik} alt="ZooLis" />
          </a>
          <a
            href="https://epicentrk.ua/ua/merchant/blenda-sop/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            Бленда-Шоп
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://rozetka.com.ua/seller/mesnik/goods/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={popRoz} alt="Месник" />
          </a>
          <a
            href="https://rozetka.com.ua/seller/mesnik/goods/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            Месник
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://rozetka.com.ua/seller/gabrus/goods/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={popRoz} alt="GabRus" />
          </a>
          <a
            href="https://rozetka.com.ua/seller/gabrus/goods/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            GabRus
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://epicentrk.ua/ua/merchant/s-mirror/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={css.popRozOP} src={epik} alt="ZooLis" />
          </a>
          <a
            href="https://epicentrk.ua/ua/merchant/s-mirror/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            S-mirror
          </a>
        </div>
        <div className={css.wrapOnePosRoz}>
          <a
            href="https://rozetka.com.ua/seller/italy_at_your_home/goods/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={css.popRozOP}
              src={popRoz}
              alt="Italy at your home"
            />
          </a>
          <a
            href="https://rozetka.com.ua/seller/italy_at_your_home/goods/"
            target="_blank"
            rel="noopener noreferrer"
            className={css.pInDecasRozetA}
          >
            Italy at your home
          </a>
        </div>
      </div>
    </div>
  );
};
export default OurWorks;
